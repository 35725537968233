import React, { useState } from 'react'
import cn from 'classnames'

import { Link, Typography } from '@UIKit'
import { Wrapper } from '@components/App/Wrapper'
import Clock from '../../../../../../assets/images/voda/clock.inline.svg'

import ArrowIcon from '../../../../../../assets/images/weter/arrowIcon.inline.svg'

import * as styles from './Goal.module.scss'

const stubs = [
  { time: '2021-2023', title: 'Моделирование' },
  { time: '2023-2024', title: 'Документация по оптимальной модели' },
  { time: '2024-2025', title: 'Утверждение оптимальной модели и документации' },
  { time: '2026', title: 'Проектная документация по заданию заказчика и реализация объекта' },
]

// TODO: rename to RoadMap
export const Goal: React.FC = () => {
  const [animated, setAnimated] = useState(false)
  return (
    <section className={styles.root}>
      <div className={styles.hide}>
        <div className={styles.content}>
          <Clock />
          <Typography size={32} weight={700}>landing.in.development.title</Typography>
          <Typography size={20} weight={400}>landing.in.development.roadmap</Typography>
        </div>
      </div>
      <Wrapper className={styles.blur}>
        <div>
          <Typography className={styles.title} size={64} weight={500} color="white">
            Дорожная карта
          </Typography>

          <Typography size={20}>
            Некое описание дорожной карты
          </Typography>

          <div className={styles.roadMap}>
            <Link
              className={cn(styles.link, animated && styles.animated)}
              to="https://my.tiaglin.com/project/wtr/development"
              allowEn
              target="_blank"
              color="primary"
              onAnimationEnd={() => setAnimated(false)}
            >
              <Typography size={20} color="inherit" missOnAlias>
                roadmap.read
              </Typography>
              <ArrowIcon className={styles.icon} width={16} height={16} />
            </Link>
          </div>
          <div className={styles.grid}>
            {stubs.map(({ time, title }) => (
              <div className={styles.step} key={title} onClick={() => setAnimated(true)}>
                <Typography size={24} weight={700} color="inherit">
                  {time}
                </Typography>
                <Typography size={20} color="inherit">
                  {title}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    </section>
  )
}
