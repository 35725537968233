// extracted by mini-css-extract-plugin
export var root = "OldSolutions-module--root--8c3e7";
export var title = "OldSolutions-module--title--8e148";
export var vs = "OldSolutions-module--vs--ecf6b";
export var reasons = "OldSolutions-module--reasons--8aeda";
export var background = "OldSolutions-module--background--4c116";
export var point = "OldSolutions-module--point--13d47";
export var ml = "OldSolutions-module--ml--15538";
export var mr = "OldSolutions-module--mr--0c6e8";
export var reason = "OldSolutions-module--reason--9787f";
export var image = "OldSolutions-module--image--8801a";
export var icon = "OldSolutions-module--icon--22fb1";