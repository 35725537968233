// extracted by mini-css-extract-plugin
export var VideoPresentationBg = "Root-module--VideoPresentationBg--2cb5e";
export var video = "Root-module--video--808ec";
export var videoMap = "Root-module--videoMap--a2984";
export var news = "Root-module--news--ab254";
export var text = "Root-module--text--917ea";
export var title = "Root-module--title--b1bb9";
export var content = "Root-module--content--d39cf";
export var form = "Root-module--form--03afe";
export var wrapper = "Root-module--wrapper--fb419";
export var subtitle = "Root-module--subtitle--9360c";
export var inputs = "Root-module--inputs--8f51c";
export var links = "Root-module--links--a8292";
export var item = "Root-module--item--b261e";
export var icon = "Root-module--icon--a966a";
export var social = "Root-module--social--4edc9";