/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { useVodaVisualizations, useNews, useVodaNews, useVodaPatents } from '@graphql'

import * as styles from './Root.module.scss'

import { AppContext } from '@components/App'
import {
  Goal,
  Technology,
  OldSolutions,
} from '@components/voda'

import {
  Main,
  VideoPresentation,
  Author,
  Committee,
  Visualization,
  Patents,
  Important,
  StockCharts,
  Footer,
  Invest,
  Map,
  News,
  Streams,
  Future,
  Header,
} from '@components/sections'

import { Modal } from '@components/App/Modal'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { EmbedVideo, Typography } from '@UIKit'
import { Content, getContent } from '@utils'
import { getNewsData } from '../../../../../api/news'
import threads from '../../../../assets/images/weter/socialIcon/Threads.svg'
import twitter from '../../../../assets/images/weter/socialIcon/Twitter.svg'
import telegram from '../../../../assets/images/weter/socialIcon/Telegram.svg'
import instagram from '../../../../assets/images/weter/socialIcon/Instagram.svg'
import facebook from '../../../../assets/images/weter/socialIcon/Facebook.svg'
import linkedin from '../../../../assets/images/weter/socialIcon/LinkedIn.svg'
import youtube from '../../../../assets/images/weter/socialIcon/YouTube.svg'
import LogoIcon from '../../../../assets/images/voda.inline.svg'
import BrandIcon from '../../../../assets/images/voda/voda.inline.svg'
import { streams } from '../../../share/consts/streams'
import { getApi } from '../../../../../api/_utils'

import mapMp4 from '@assets/videos/voda/map.mp4'
import mapWebm from '@assets/videos/voda/map.webm'

const maps = {
  iframe: (
    <iframe
      title="weter"
      src="https://embed.windy.com/embed2.html?lat=25.2048&lon=55.2708&zoom=3&level=surface&overlay=waves&menu=false&message=&marker=&calendar=&pressure=&type=map&location=coordinates&detail=&detailLat=41.9028&detailLon=12.4964&metricWind=m%2Fs&metricTemp=%C2%B0C&radarRange=-1"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  ),
  video: (
    <video className={styles.videoMap} autoPlay playsInline loop muted>
      <source src={mapWebm} type="video/webm" />
      <source src={mapMp4} type="video/mp4" />
    </video>
  ),
}

const logoIconProps = <LogoIcon width={40} height={40} />
const brandIconProps = <BrandIcon />

export const Root = () => {
  const { langCode } = useContext(AppContext)
  const news = useVodaNews()
  const patents = useVodaPatents()

  const VideoPresentationBg = (
    <StaticImage
      className={styles.VideoPresentationBg}
      src="../../../../assets/images/voda/videoPresentationBackground.jpg"
      alt="presentation thumbnail"
      placeholder="blurred"
      quality={90}
    />
  )

  const [presentationModal, setPresentationModal] = useState(false)
  const [selectedNews, setSelectedNews] = useState(0)
  const [newsModal, setNewsModal] = useState(false)
  const [selectedDescription, setSelectedDescription] = useState<Content[]>([])
  const [investorCount, setInvestorCount] = useState(1674)

  useEffect(() => {
    const getDescription = async () => {
      const description: Content[] = await getNewsData(news[selectedNews].id).then(({ data }) => data.content)
      setSelectedDescription(description)
    }

    getDescription()
  }, [selectedNews])

  useEffect(() => {
    if (!['/ru', '/'].includes(window.location.pathname)) {
      window.location.pathname = '/'
    }

    const getInvestorCount = async () => {
      const count = await fetch(getApi('projects/projects/vod/investorcount'))
        .then((data) => data.json())
        .then(({ data }) => data.count)

      setInvestorCount(count)
    }

    getInvestorCount()
  }, [])

  const openPresentationModal = () => {
    setPresentationModal(true)
  }

  const nextNews = () => {
    // setSelectedNews((state) => (state + 1) % news.length)
  }
  const prevNews = () => {
    // setSelectedNews((state) => (state - 1 < 0 ? news.length - 1 : state - 1))
  }
  const openNewsModal = () => {
    setNewsModal(true)
  }
  const linksData = [
    {
      icon: threads,
      content: 'Threads',
      url: 'https://www.threads.net/@weter_world',
    },
    {
      icon: twitter,
      content: 'Twitter',
      url: 'https://twitter.com/denistiaglin',
    },
    {
      icon: telegram,
      content: 'Telegram',
      url: 'https://t.me/WeterProjectBot',
    },
    {
      icon: instagram,
      content: 'Instagram',
      url: 'https://www.instagram.com/weter_world/',
    },
    {
      icon: facebook,
      content: 'Facebook',
      url: 'https://www.facebook.com/groups/tiaglinwetergorod/',
    },
    {
      icon: linkedin,
      content: 'Linkedin',
      url: 'https://www.linkedin.com/in/denis-tiaglin-03484358/',
    },
    {
      icon: youtube,
      content: 'YouTube',
      url: 'https://www.youtube.com/c/DENISTIAGLIN',
    },
  ]
  const videos = {
    // ru: 'https://youtu.be/',
    en: 'Pzd7tylXQe8',
  }

  return (
    <>
      <Header brandIcon={brandIconProps} logoIcon={logoIconProps} />
      <main>
        <Main
          selectedNews={selectedNews}
          changeSelectedNews={{ prevNews, nextNews }}
          openNewsModal={openNewsModal}
          openPresentationModal={openPresentationModal}
          newsMain={news}
          project={'voda'}
          title={'V.O.D.A'}
          video={'landing.main.video.title'}
          time={'3:35'}
        />
        <Technology />
        <Map mapsProp={maps} />
        <Important
          cons={{
            title: 'landing.important.cons.title',
            point1: 'landing.important.cons.point1',
            point2: 'landing.important.cons.point2',
            point3: 'landing.important.cons.point3',
            bgImage: (
              <StaticImage
                placeholder="blurred"
                src="../../../../assets/images/weter/Bad.jpg"
                alt="cons"
              />
            ),
          }}
          pros={{
            title: 'landing.important.pros.title',
            point1: 'landing.important.pros.point1',
            point2: 'landing.important.pros.point2',
            point3: 'landing.important.pros.point3',
            bgImage: (
              <StaticImage
                placeholder="blurred"
                src="../../../../assets/images/voda/Good.jpg"
                alt="cons"
              />
            ),
          }}
        />
        <OldSolutions />
        <VideoPresentation openPresentationModal={openPresentationModal} bg={VideoPresentationBg} />
        <Visualization useVisualizationHook={useVodaVisualizations} />
        <Author />
        <Patents patents={patents} />
        <Goal />
        <News
          setSelectedNews={setSelectedNews}
          openNewsModal={openNewsModal}
          newsHook={news}
          disabled
        />
        <Streams streams={[{ ...streams.a, technical: false }, { ...streams.b, technical: true }, { ...streams.c, technical: true }]} />
        <StockCharts name={'V.O.D.A'} title={'V.O.D.A'} />
        <Invest countNumber={investorCount} project={'voda'} />
        <Committee />
        <Future theme={'dark'} />
      </main>
      <Footer project={'voda'} />
      <Modal className={styles.video} isOpen={presentationModal} onRequestClose={() => setPresentationModal(false)}>
        <EmbedVideo youtubeID={videos.en} />
      </Modal>
      <Modal className={styles.news} isOpen={newsModal} onRequestClose={() => setNewsModal(false)}>
        {news[selectedNews].mainImage ? (
          <GatsbyImage image={news[selectedNews].mainImage} alt="" />
        ) : (
          <StaticImage
            src="../../../../assets/images/weter/newsPoster.jpg"
            alt="WETER LOGO"
            placeholder="blurred"
            aspectRatio={1.77} // 16 / 9
          />
        )}
        <div className={styles.text}>
          <Typography
            className={styles.title}
            size={32}
            dangerouslySetInnerHTML={{ __html: getContent(news[selectedNews].title) }}
          />
          <Typography
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: getContent(selectedDescription).replaceAll(/<img[^>]*>|<hr[^>]*>[\s\S]*/gm, ''),
            }}
          />
        </div>
        <div className={styles.links}>
          {linksData.map((link) => (
            <a href={link.url} key={link.content} className={styles.item}>
              <img className={styles.icon} src={link.icon} alt={link.content} />
              <Typography disableLocalize className={styles.social} size={16} weight={500}>
                {link.content}
              </Typography>
            </a>
          ))}
        </div>
      </Modal>
    </>
  )
}
