import React from 'react'

import compact from '../../../../../assets/images/voda/Technology/compact.png'
import cycle from '../../../../../assets/images/voda/Technology/cycle.png'
import drive from '../../../../../assets/images/voda/Technology/drive.png'

import * as styles from './TechnologyBlock.module.scss'
import { Typography } from '@UIKit'

const content = [
  {
    title: 'technology.block.1.title',
    subtitle: 'technology.block.1.subtitle',
    img: compact,
    index: 1,
  },
  {
    title: 'technology.block.2.title',
    subtitle: 'technology.block.2.subtitle',
    img: cycle,
    index: 2,
  },
  {
    title: 'technology.block.3.title',
    subtitle: 'technology.block.3.subtitle',
    img: drive,
    index: 3,
  },
]

export const TechnologyBlock: React.FC = () => (
  <div className={styles.flex}>
    {content.map(({ img, title, subtitle, index }) => (
      <div key={index} className={styles.card}>
        <img className={styles.img} src={img} alt="title" />
        <div className={`${styles.text} ${index === 2 ? styles.left : ''}`}>
          <Typography size={24} weight={500} className={styles.title}>{title}</Typography>
          <Typography size={20} weight={400}>{subtitle}</Typography>
        </div>
      </div>
    ))}
  </div>
)
