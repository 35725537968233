// extracted by mini-css-extract-plugin
export var root = "Technology-module--root--2d839";
export var flex = "Technology-module--flex--defa6";
export var content = "Technology-module--content--15114";
export var carousel = "Technology-module--carousel--11583";
export var slide = "Technology-module--slide--e33e3";
export var image = "Technology-module--image--90eda";
export var pattern = "Technology-module--pattern--7e621";
export var slide1 = "Technology-module--slide1--1a97c";
export var name1 = "Technology-module--name1--c190e";
export var name2 = "Technology-module--name2--5973d";
export var slide2 = "Technology-module--slide2--e59fc";
export var steps = "Technology-module--steps--58ea4";
export var counter = "Technology-module--counter--0fae2";
export var slide3 = "Technology-module--slide3--1619a";
export var slide4 = "Technology-module--slide4--e1f3b";
export var item1 = "Technology-module--item1--fcbc0";
export var item2 = "Technology-module--item2--906ce";
export var title1 = "Technology-module--title1--b55fb";
export var description1 = "Technology-module--description1--94f87";
export var item3 = "Technology-module--item3--fa84d";
export var slide5 = "Technology-module--slide5--bc47e";
export var point = "Technology-module--point--df98d";
export var liquid = "Technology-module--liquid--350e3";
export var oxy = "Technology-module--oxy--0a755";
export var liquidBottom = "Technology-module--liquidBottom--9d557";
export var slide6 = "Technology-module--slide6--7e82b";
export var slide7 = "Technology-module--slide7--a5aaf";
export var counterAbsolute = "Technology-module--counterAbsolute--7bf91";
export var description = "Technology-module--description--f3b6e";