import React, { useEffect, useState } from 'react'

import * as styles from './Map.module.scss'

import { Typography } from '@UIKit'
import { iOS } from '@utils'

interface MapProps {
  mapsProp?: React.FC
}

export const Map: React.FC<MapProps> = ({ mapsProp = maps }) => {
  const [displayedMap, setDisplayedMap] = useState<'iframe' | 'video'>()

  useEffect(() => {
    setDisplayedMap(iOS() ? 'video' : 'iframe')
  }, [])

  return (
    <section className={styles.root}>
      <div className={styles.wrapper}>{displayedMap && mapsProp[displayedMap]}</div>

      <div className={styles.cover}>
        <Typography size={64} weight={500}>
          map.title
        </Typography>
        <Typography className={styles.description} size={24} weight={700}>
          map.description
        </Typography>
      </div>
    </section>
  )
}
