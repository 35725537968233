import React from 'react'

import * as styles from './OldSolutions.module.scss'
import { Typography } from '@UIKit'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import V from '../../../../../../assets/images/voda/OldSolutions/V.inline.svg'
import X from '../../../../../../assets/images/voda/OldSolutions/X.inline.svg'

const reasons = [
  {
    backgroundImage: (
      <StaticImage
        className={styles.image}
        placeholder="blurred"
        src="../../../../../../assets/images/voda/OldSolutions/BadBg.png"
        alt="cons"
      />
    ),
    index: 1,
    points: [
      'old.solutions.cons.point1',
      'old.solutions.cons.point2',
      'old.solutions.cons.point3',
      'old.solutions.cons.point4',
    ],
  },
  {
    backgroundImage: (
      <StaticImage
        className={styles.image}
        placeholder="blurred"
        src="../../../../../../assets/images/voda/OldSolutions/GoodBg.png"
        alt="pros"
      />
    ),
    index: 2,
    points: [
      'old.solutions.pros.point1',
      'old.solutions.pros.point2',
      'old.solutions.pros.point3',
      'old.solutions.pros.point4',
    ],
  },
]
export const OldSolutions: React.FC = () => (
  <section className={styles.root}>
    <div className={styles.reasons}>
      <Typography className={styles.title} size={64} weight={500}>
        landing.old.solutions.title
      </Typography>
      <Typography disableLocalize className={styles.vs} size={64} weight={500}>
        VS
      </Typography>
      {reasons.map((reason) => (
        <div className={styles.reason} key={reason.index} {...reason}>
          {reason.backgroundImage}

          {reason.points.map((point, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={cn(styles.point, {
                [styles.ml]: reason.index === 2,
                [styles.mr]: reason.index === 1,
              })}
            >
              {reason.index === 1 && (
                <span className={styles.icon}>
                  <X />
                </span>
              )}
              {reason.index === 2 && (
                <span className={styles.icon}>
                  <V />
                </span>
              )}
              <Typography size={32} weight={500}>{point}</Typography>
            </span>
          ))}
        </div>
      ))}
    </div>
  </section>
)
