// extracted by mini-css-extract-plugin
export var root = "LatestNewsCard-module--root--2b2da";
export var cover = "LatestNewsCard-module--cover--ecb1b";
export var date = "LatestNewsCard-module--date--0857d";
export var text = "LatestNewsCard-module--text--ff8e8";
export var title = "LatestNewsCard-module--title--35c99";
export var description = "LatestNewsCard-module--description--e4dbd";
export var info = "LatestNewsCard-module--info--7bc4b";
export var divider = "LatestNewsCard-module--divider--da25d";
export var indicator = "LatestNewsCard-module--indicator--9be75";
export var indicators = "LatestNewsCard-module--indicators--e8833";
export var actions = "LatestNewsCard-module--actions--4ea12";
export var button = "LatestNewsCard-module--button--32327";
export var p = "LatestNewsCard-module--p--494d6";
export var count = "LatestNewsCard-module--count--937b3";
export var icon = "LatestNewsCard-module--icon--f19a7";
export var item = "LatestNewsCard-module--item--9b055";