import React, { useState, useEffect } from 'react'
import * as styles from './Technology.module.scss'
import { Carousel, Typography } from '@UIKit'
import { TechnologyBlock } from '@components/voda/TechnologyBlock'
import slide1 from '../../../../../../assets/images/voda/Technology/Slide1.png'
import slide2 from '../../../../../../assets/images/voda/Technology/Slide2.png'
import slide3 from '../../../../../../assets/images/voda/Technology/Slide3.png'
import slide4 from '../../../../../../assets/images/voda/Technology/Slide4.png'
import slide5 from '../../../../../../assets/images/voda/Technology/Slide5.png'
import slide6 from '../../../../../../assets/images/voda/Technology/Slide6.png'
import slide7 from '../../../../../../assets/images/voda/Technology/Slide7.png'
import cn from 'classnames'
import { getTranslate } from 'react-localize-alias'

export const Technology: React.FC = () => {
  const [slidesPerView, setSlidesPerView] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 540) {
        setSlidesPerView(0)
      } else if (window.innerWidth <= 768) {
        setSlidesPerView(30)
      }
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const slides = [
    {
      type: 'slide1',
      content: (
        <>
          <div className={cn(styles.slide1, styles.pattern)}>
            <div className={styles.counter}>
              <span>01</span>
              <span>/ 10</span>
            </div>
            <div className={cn(styles.counter, styles.counterAbsolute)}>
              <span>02</span>
              <span>/ 10</span>
            </div>
            <span className={styles.name1}>{getTranslate('technology.slide.1.title')}</span>
            <span className={styles.name2}>{getTranslate('technology.slide.2.title')}</span>
          </div>
          <img className={styles.image} src={slide1} alt="Slide 1" />
        </>
      ),
    },
    {
      type: 'slide2',
      content: (
        <>
          <div className={cn(styles.slide2, styles.pattern)}>
            <div className={styles.counter}>
              <span>03</span>
              <span>/ 10</span>
            </div>
            <span className={styles.name1}>{getTranslate('technology.slide.3.title')}</span>
            <span className={styles.steps}>{getTranslate('technology.slide.3.description')}</span>
          </div>
          <img className={styles.image} src={slide2} alt="Slide 2" />
        </>
      ),
    },
    {
      type: 'slide3',
      content: (
        <>
          <div className={cn(styles.slide3, styles.pattern)}>
            <div className={styles.counter}>
              <span>04</span>
              <span>/ 10</span>
            </div>
            <div className={cn(styles.counter, styles.counterAbsolute)}>
              <span>05</span>
              <span>/ 10</span>
            </div>
            <span className={styles.name1}>{getTranslate('technology.slide.4.title')}</span>
            <span className={styles.name2}>{getTranslate('technology.slide.5.title')}</span>
          </div>

          <img className={styles.image} src={slide3} alt="Slide 3" />
        </>
      ),
    },
    {
      type: 'slide4',
      content: (
        <>
          <div className={cn(styles.slide4, styles.pattern)}>
            <div className={styles.counter}>
              <span>06</span>
              <span>/ 10</span>
            </div>
            <div className={cn(styles.counter, styles.counterAbsolute)}>
              <span>07</span>
              <span>/ 10</span>
            </div>
            <span className={styles.name1}>{getTranslate('technology.slide.6.title')}</span>
            <div className={styles.item1}>
              <span className={styles.title1}>{getTranslate('technology.slide.6.subtitle.1')}</span>
              <span className={styles.description1}>{getTranslate('technology.slide.6.description.1')}</span>
            </div>
            <div className={styles.item2}>
              <span className={styles.title1}>{getTranslate('technology.slide.6.subtitle.2')}</span>
              <span className={styles.description1}>{getTranslate('technology.slide.6.description.2')}</span>
            </div>
            <div className={styles.item3}>
              <span className={styles.title1}>{getTranslate('technology.slide.7.subtitle.1')}</span>
              <span className={styles.description1}>{getTranslate('technology.slide.7.description.1')}</span>
            </div>
          </div>
          <img className={styles.image} src={slide4} alt="Slide 4" />
        </>
      ),
    },
    {
      type: 'slide5',
      content: (
        <>
          <div className={cn(styles.slide5, styles.slide2, styles.pattern)}>
            <div className={styles.counter}>
              <span>08</span>
              <span>/ 10</span>
            </div>
            <span className={styles.name1}>{getTranslate('technology.slide.8.title')}</span>
            <span className={styles.steps}>{getTranslate('technology.slide.8.description')}</span>
            <span className={cn(styles.point, styles.liquid)}>{getTranslate('technology.slide.8.liquid')}</span>
            <span className={cn(styles.point, styles.oxy)}>{getTranslate('technology.slide.8.oxygen')}</span>
            <span className={cn(styles.point, styles.liquidBottom)}>{getTranslate('technology.slide.8.liquid')}</span>
          </div>

          <img className={styles.image} src={slide5} alt="Slide 5" />
        </>
      ),
    },
    {
      type: 'slide6',
      content: (
        <>
          <div className={cn(styles.slide6, styles.slide2, styles.pattern)}>
            <div className={styles.counter}>
              <span>09</span>
              <span>/ 10</span>
            </div>
            <span className={styles.name1}>{getTranslate('technology.slide.9.title')}</span>
            <span className={styles.steps}>{getTranslate('technology.slide.9.description.1')}</span>
            <span className={styles.point}>{getTranslate('technology.slide.9.valve')}</span>
            <span className={styles.steps}>{getTranslate('technology.slide.9.description.2')}</span>
          </div>
          <img className={styles.image} src={slide6} alt="Slide 6" />
        </>
      ),
    },
    {
      type: 'slide7',
      content: (
        <>
          <div className={cn(styles.slide7, styles.slide2, styles.pattern)}>
            <div className={styles.counter}>
              <span>10</span>
              <span>/ 10</span>
            </div>
            <span className={styles.name1}>{getTranslate('technology.slide.10.title')}</span>
            <span className={styles.steps}>{getTranslate('technology.slide.10.description')}</span>
          </div>
          <img className={styles.image} src={slide7} alt="Slide 7" />
        </>
      ),
    },
  ]
  const renderedSlides = slides.map((slide, index) => ({
    ...slide,
    key: `${slide.type}_${index}`,
    label: `0${index + 1}`,
  }))
  return (
    <>
      <section className={styles.root} id="HowItWork">
        <div className={styles.flex}>
          <div className={styles.content}>
            <Typography size={48} weight={500}>
              landing.technology.title
            </Typography>
            <Typography size={20} weight={400} color={'default'}>
              technology.description
            </Typography>
          </div>
          <div className={styles.carousel}>
            <Carousel
              slidesPerView={1.1}
              spaceBetween={slidesPerView}
              centeredSlides={false}
              navigation={false}
              pagination={{
                clickable: true,
              }}
              slides={renderedSlides.map((renderedSlide) => (
                <div key={renderedSlide.key} className={styles.slide}>
                  {renderedSlide.content}
                </div>
              ))}
            />
          </div>
        </div>
        <TechnologyBlock />
      </section>
    </>
  )
}
