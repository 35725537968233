// extracted by mini-css-extract-plugin
export var root = "Goal-module--root--378e5";
export var hide = "Goal-module--hide--a3df3";
export var content = "Goal-module--content--236e6";
export var blur = "Goal-module--blur--dda44";
export var title = "Goal-module--title--c28b7";
export var subtitle = "Goal-module--subtitle--3639e";
export var roadMap = "Goal-module--roadMap--edd3f";
export var grid = "Goal-module--grid--1d8f1";
export var step = "Goal-module--step--4e471";
export var link = "Goal-module--link--53550";
export var animated = "Goal-module--animated--00658";
export var blink = "Goal-module--blink--bfa8b";
export var icon = "Goal-module--icon--e777f";