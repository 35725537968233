/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import * as styles from './Main.module.scss'

import TelegramIcon from '../../../../../assets/images/weter/telegramIcon.inline.svg'
import InstagramIcon from '../../../../../assets/images/weter/instagramIcon.inline.svg'
import PlayIcon from '../../../../../assets/images/weter/playIcon.inline.svg'

import { LatestNewsCard } from '@components/sections'
import { Wrapper } from '@components/App/Wrapper'
import { Hexagon, Link, Typography } from '@UIKit'
import { getTranslate } from 'react-localize-alias'
import { useInterval } from '@hooks'

const socialMedia = {
  telegram: 'https://t.me/WETERNEWSGOROD',
  instagram: 'https://www.instagram.com/denis_tiaglin',
}

const backgroundsCount = 2
const delay = 5000

interface MainProps {
  newsMain?: () => void
  selectedNews: number
  openPresentationModal: () => void
  openNewsModal: () => void
  changeSelectedNews: {
    prevNews: () => void
    nextNews: () => void
  }
  project?: 'weter' | 'voda'
  title?: string
  description?: string
  video?: string
  time?: string
}

export const Main: React.FC<MainProps> = ({
  newsMain,
  selectedNews,
  openPresentationModal,
  openNewsModal,
  changeSelectedNews,
  project = 'weter',
  title = 'main.title',
  description = 'main.description',
  video = 'main.video.title',
  time = '11:18',
}) => {
  const [active, setActive] = useState(0)
  useInterval(() => setActive((index) => (index + 1) % backgroundsCount), delay)

  return (
    <section className={cn(styles.root)}>
      {project === 'weter' &&
        <div className={styles.backgrounds}>
          <div className={cn(styles.background)}>
            <StaticImage
              layout="fullWidth"
              src="../../../../../assets/images/weter/coverBg1.jpg"
              alt=""
              loading="eager"
              placeholder="blurred"
              quality={100}
            />
          </div>
          <div className={cn(styles.background)}>
            <StaticImage
              layout="fullWidth"
              src="../../../../../assets/images/weter/coverBg2.jpg"
              alt=""
              loading="eager"
              placeholder="blurred"
              quality={100}
            />
          </div>
        </div>
      }
      {project === 'voda' &&
        <div className={styles.backgrounds}>
          <div className={cn(styles.background)}>
            <div className={styles.gradient}><br /></div>
            <div className={styles.gradientTop}><br /></div>
            <div className={styles.gradientLeft}><br /></div>
            <StaticImage
              layout="fullWidth"
              src="../../../../../assets/images/voda/coverBg1.png"
              alt=""
              loading="eager"
              placeholder="blurred"
              quality={100}
            />
          </div>
        </div>
      }
      <Wrapper className={styles.wrapper}>
        <LatestNewsCard
          selectedNews={selectedNews}
          openNewsModal={openNewsModal}
          changeSelectedNews={changeSelectedNews}
          news={newsMain}
        />

        <div className={styles.main}>
          <div className={styles.top}>
            <div className={styles.container}>
              <Typography className={styles.title} size={64} weight={700} disableLocalize>
                {title}
              </Typography>
              {project === 'weter' &&
                <div className={styles.subtitle}>
                  <Typography size={48} weight={500}>
                    main.subtitle
                  </Typography>
                </div>
              }
              <div className={styles.description}>
                <Typography size={24} weight={700}>
                  {description}
                </Typography>
              </div>
            </div>
            <div className={styles.links}>
              <Link className={styles.item} to={socialMedia.telegram} target="_blank">
                <TelegramIcon />
              </Link>
              <Link className={styles.item} to={socialMedia.instagram} target="_blank">
                <InstagramIcon />
              </Link>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.hexagon} onClick={openPresentationModal}>
              <Hexagon className={styles.back} border cursor="pointer">
                <PlayIcon className={styles.icon} />
              </Hexagon>
            </div>
            <div className={styles.video}>
              <Typography size={24} weight={700}>
                {video}
              </Typography>
              <Typography size={20} disableLocalize>
                {time}
              </Typography>
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  )
}
