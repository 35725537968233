import React from 'react'

import * as styles from './ImportantBlock.module.scss'
import { Typography } from '@UIKit'
import { getTranslate } from 'react-localize-alias'

interface ImportantBlockProps {
  bgImage: React.ReactNode
  iconImage: React.ReactNode
  title: string
  point1: string
  point2: string
  point3: string
}

export const ImportantBlock: React.FC<ImportantBlockProps> = ({ bgImage, iconImage, title, point1, point2, point3 }) => (
  <div className={styles.reason}>
    <div className={styles.background}>{bgImage}</div>

    <div className={styles.description}>
      <div className={styles.icon}>{iconImage}</div>
      <Typography className={styles.title} size={24} weight={700}>
        {title}
      </Typography>
      <ul className={styles.list}>
        <li>
          <Typography size={20} disableLocalize>
            - {getTranslate(point1)}
          </Typography>
        </li>
        <li>
          <Typography size={20} disableLocalize>
            - {getTranslate(point2)}
          </Typography>
        </li>
        <li>
          <Typography size={20} disableLocalize>
            - {getTranslate(point3)}
          </Typography>
        </li>
      </ul>
    </div>
  </div>
)
