// extracted by mini-css-extract-plugin
export var root = "Main-module--root--9ab01";
export var rootVoda = "Main-module--rootVoda--66a55";
export var backgrounds = "Main-module--backgrounds--4f6ae";
export var container = "Main-module--container--c9867";
export var background = "Main-module--background--ccde5";
export var gradient = "Main-module--gradient--0cfe0";
export var gradientTop = "Main-module--gradientTop--587ac";
export var gradientLeft = "Main-module--gradientLeft--4ab8f";
export var wrapper = "Main-module--wrapper--eb852";
export var header = "Main-module--header--e2377";
export var news = "Main-module--news--5de69";
export var main = "Main-module--main--d653e";
export var top = "Main-module--top--c3ac3";
export var links = "Main-module--links--772cb";
export var description = "Main-module--description--385ce";
export var bottom = "Main-module--bottom--f86b3";
export var hexagon = "Main-module--hexagon--54cc4";
export var video = "Main-module--video--42b0c";
export var title = "Main-module--title--bb1f1";
export var subtitle = "Main-module--subtitle--94336";
export var back = "Main-module--back--557de";
export var icon = "Main-module--icon--feb9e";
export var item = "Main-module--item--9f0d5";