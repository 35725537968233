import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import * as styles from './Visualization.module.scss'

import { Carousel, Typography } from '@UIKit'
import { Modal } from '@components/App/Modal'
import Swiper from 'swiper'

interface VisualizationProps {
  useVisualizationHook: () => {
    cropped: any[];
    full: any[];
  };
}
export const Visualization: React.FC<VisualizationProps> = ({ useVisualizationHook }) => {
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null)

  const [modal, setModal] = useState(false)
  const [swiper, setSwiper] = useState<Swiper>()

  const { cropped, full } = useVisualizationHook()
  const selectedImage = full[selectedIndex || 0]
  const isVertical =
    selectedImage.childImageSharp.gatsbyImageData.height >= selectedImage.childImageSharp.gatsbyImageData.width

  const openModal = (index: number) => {
    swiper?.autoplay.stop()
    setSelectedIndex(index)
    setModal(true)
  }
  const closeModal = () => {
    swiper?.autoplay.start()
    setModal(false)
  }

  return (
    <>
      <section className={styles.root} id="Visualization">
        <Typography className={styles.title} size={64} weight={500} missOnAlias>
          weter.visualization.title
        </Typography>

        <Carousel
          slidesPerView={4}
          navigation
          spaceBetween={5}
          className={styles.carousel}
          autoplay={{ disableOnInteraction: false }}
          slides={cropped.map(({ name, childImageSharp: { gatsbyImageData } }) => (
            <GatsbyImage className={styles.image} key={name} image={gatsbyImageData} alt={name} />
          ))}
          onSwiper={setSwiper}
          onClick={(_, index) => openModal(index)}
        />
      </section>

      <Modal className={cn(styles.modal, isVertical && styles.vertical)} isOpen={modal} onRequestClose={closeModal}>
        <GatsbyImage image={selectedImage.childImageSharp.gatsbyImageData} alt={selectedImage.name} />
      </Modal>
    </>
  )
}
